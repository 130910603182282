<template>
  <div>
    <div class="row start-xs">
      <div class="col-xs-9">
        <div class="buttons"><button
          class="button button-with-icon"
          type="button"
          @click="selectFile"
          v-if="!uploadEnd && !uploading && !url"
        ><span>Enviar imagem</span><i class="icon-file_upload"></i></button>
        <input
          style="display: none"
          id="files"
          type="file"
          name="file"
          ref="uploadInput"
          accept="image/*"
          :multiple="false"
          @change="detectFiles($event)"
        >
        </div>
        <div v-if="!uploadEnd"
          class="pie-wrapper style-3"
          v-bind:class="['progress-'+ progressUpload]"
          v-show="uploading || uploadEnd"
        >
          <span class="label">
            {{ progressUpload }}
            <span class="smaller">%</span>
          </span>
          <div class="pie">
            <div class="left-side half-circle"></div>
            <div class="right-side half-circle"></div>
          </div>
          <div class="shadow"></div>
        </div>
      </div>
    </div>
    <div class="col-xs-12">
      <div class="upload-row">
        <img v-if="uploadEnd" :src="downloadURL">
        <img v-if="url" :src="url">
        <div v-if="uploadEnd || url">
          <button type="button" class="button button-small button-red" @click="deleteImage()"><i class="icon-delete_forever"></i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadComponent',
  data() {
    return {
      progressUpload: 0,
      fileName: "",
      uploadTask: "",
      uploading: false,
      uploadEnd: false,
      firestorage: this.$firebase.storage(),
      downloadURL: ""
    };
  },
  props: {
    url: {
      type: String,
      required: true
    }
  },
  methods: {
    selectFile() {
      this.$refs.uploadInput.click();
    },
    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      });
    },
    upload(file) {
      this.fileName = file.name;
      this.uploading = true;
      this.uploadTask = this.firestorage.ref("images/" + file.name).put(file);
    },
    deleteImage() {
      this.firestorage
        .ref("images/" + this.fileName)
        .delete()
        .then(() => {
          this.uploading = false;
          this.uploadEnd = false;
          this.downloadURL = "";
          this.$emit('update-url', "");
          this.$emit("changeUrl", "");
        })
        .catch(error => {
          alert(`file delete error occured: ${error}`);
        });
    }
  },
  mounted() {
      if(this.url){
          this.uploadEnd = true
          this.downloadURL = this.url
      }
  },
  watch: {
    uploadTask: function() {
      this.uploadTask.on(
        "state_changed",
        sp => {
          this.progressUpload = Math.floor(
            (sp.bytesTransferred / sp.totalBytes) * 100
          );
        },
        null,
        () => {
            
          this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            this.uploadEnd = true;
            this.downloadURL = downloadURL;
            //   this.$emit('downloadURL', downloadURL)
            this.$emit("change-url", downloadURL);
          });
        }
      );
    }
    // downloadURL: function(){
    //     this.url = this.downloadURL
    // }
  }
};
</script>

<style lang="scss">
$red: #e05757;
.button-red{
  background: $red;
  transition: .3s all;
  &:hover{
    background: darken($red,5%);
  }
}
.upload-row {
  display: flex;
  height: 60px;
  max-width: 100%;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
$bg-color: #34495e;
$default-size: 4em;
$label-font-size: $default-size / 4;
$label-font-size-redo: $default-size * 4;

// -- mixins
@mixin size($width, $height) {
  height: $height;
  width: $width;
}

@mixin draw-progress($progress, $color) {
  .pie {
    .half-circle {
      border-color: $color;
    }

    .left-side {
      transform: rotate($progress * 3.6deg);
    }

    @if $progress <= 50 {
      .right-side {
        display: none;
      }
    } @else {
      clip: rect(auto, auto, auto, auto);

      .right-side {
        transform: rotate(180deg);
      }
    }
  }
}

@mixin draw-progress--solid($progress, $color, $bg-color) {
  background: linear-gradient(to right, $color 50%, $bg-color 50%);

  &:before {
    @if $progress <= 50 {
      background: $bg-color;
      transform: rotate((100 - (50 - $progress)) / 100 * 360deg * -1);
    } @else {
      background: $color;
      transform: rotate((100 - $progress) / 100 * 360deg);
    }
  }
}

// -- selectors
*,
*:before,
*:after {
  box-sizing: border-box;
}
.set-size {
  font-size: 10em;
}

.pie-wrapper {
  @include size($default-size, $default-size);
  float: left;
  margin: 15px;
  position: relative;

  &:nth-child(3n + 1) {
    clear: both;
  }

  .pie {
    @include size(100%, 100%);
    clip: rect(0, $default-size, $default-size, $default-size / 2);
    left: 0;
    position: absolute;
    top: 0;

    .half-circle {
      @include size(100%, 100%);
      border: ($default-size / 10) solid #3498db;
      border-radius: 50%;
      clip: rect(0, $default-size / 2, $default-size, 0);
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  .label {
    background: $bg-color;
    border-radius: 50%;
    color: #ecf0f1;
    cursor: default;
    font-size: $label-font-size;
    position: absolute;
    text-align: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    transition: .3s all;

    .smaller {
      color: #bdc3c7;
      font-size: 0.5em;
      font-weight: bolder;
    }
  }
  &.progress-100{
    animation-name: hide;
    animation-duration: 1s;
    animation-delay: 3s;

    animation-fill-mode: forwards;

     .label {
    background: #00aa99;
    z-index: 10;

  }
  }

  .shadow {
    @include size(100%, 100%);
    border: $default-size / 10 solid #535854;
    border-radius: 50%;
  }

  &.style-2 {
    .label {
      background: none;
      color: #7f8c8d;
    }
  }

  @for $i from 1 through 100 {
    &.progress-#{$i} {
      $progress-color: mix(#00aa99, #ffaf6b, $i);

      @include draw-progress($i, $progress-color);
    }
  }
}

.pie-wrapper--solid {
  border-radius: 50%;
  overflow: hidden;

  &:before {
    border-radius: 0 100% 100% 0 / 50%;
    content: "";
    display: block;
    height: 100%;
    margin-left: 50%;
    transform-origin: left;
  }

  .label {
    background: transparent;
  }

  @for $i from 1 through 100 {
    &.progress-#{$i} {
      $progress-color-solid: mix(#00aa99, #ffaf6b, $i);
      @include draw-progress--solid($i, $progress-color-solid, $bg-color);
    }
  }
}
@keyframes hide {
  from {opacity: 1;}
  to {opacity: 0;}
  100% {
      display: none
  }
}
</style>