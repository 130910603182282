
<template>
  <div class="home" v-if="!$root.categories.loading">
    <header id="header" ref="header" :class="{'full-height': $route.name == 'Home', 'subpage': $route.name != 'Home'}">
        <div class="slides">
             <parallax>
                <img v-for="(img,i) in images" :key="i" :src="img" :class="{'current': i == currentImage, 'last': i == lastImage}">
             </parallax>
        </div>
      <div class="" style="background: linear-gradient(180deg, rgba(0,0,0,0.5) 0px,  rgba(0,0,0,0) 100%); width: 100%; height: 100%; position: absolute; top: 0; left: 0;"></div>
      <div class="container">
        <div class="nav" :class="{'open': view}">
            <router-link to="/"><h1 class="logo">Campêche</h1></router-link>
            <a href="javascript:void(0)" class="toggle" id="toggle" @click="view = !view"> <i class="icon-more_vert"></i></a>
            <ul id="menu" class="_hide" v-show="view">
              <li><router-link to="/">{{ $t('header.nav.home') }}</router-link></li>
              <li class="subnav"  @mouseover="viewProduct = true" @mouseout="viewProduct = false">
                  <router-link :to="'/'+$t('routes.products')">{{ $t('header.nav.products') }}</router-link>
                  <div class="subnav-menu" :class="{'open': viewProduct}">
                      <ul class="courses">
                          <li v-for="(category, id) in $root.categories.data" :key="id"><router-link :to="'/'+$t('routes.products') + '/' + category.uri[$i18n.locale]">{{ category.name[$i18n.locale] }}</router-link></li>
                      </ul>
                  </div>
              </li>

              <li><router-link :to="'/'+$t('routes.about')">{{ $t('header.nav.about') }}</router-link></li>
              <li><router-link :to="'/'+$t('routes.contact')">{{ $t('header.nav.contact') }}</router-link></li>
            </ul>
            <ul id="top" v-show="view">
              <li class="currencies" v-if="currencyLoaded">
                
                <ul>
                  <!-- <li><strong>{{ $t('header.top.base') }}:</strong></li> -->
                  <template v-if="this.$i18n.locale == 'pt'">
                  <li v-for="(cur, key) in currency" :key="key">
                    <strong>{{key}}-BRL {{cur[0].ask}} </strong>
                    <span class="badge" :class="{'red': cur[0].pctChange < 0, 'green': cur[0].pctChange > 0, 'blue': cur[0].pctChange == 0 }">{{cur[0].pctChange}}%</span>
                  </li>
                  <li><em  class="default">{{ $t('header.top.at') }} {{ $moment(currencyDate, 'YYYY-MM-DD HH:ii').format('D/M/Y HH:mm') }}</em></li>
                  </template>
                   <template v-if="this.$i18n.locale == 'en'">
                    <li>
                        <strong>BRL-USD {{currencyUsd.BRL.ask}}</strong>
                    </li>
                    <li>
                        <strong>EUR-USD {{currencyUsd.EUR.ask}}</strong>
                    </li>
                    <li><em  class="default">{{ $t('header.top.at') }} {{ $moment(currencyUsdDate, 'YYYY-MM-DD HH:ii').format('M/D/Y') }} by <span  v-tooltip="{content: 'European Central Bank', delay: { show: 500, hide: 300,},}">ECB</span></em></li>
                  </template>
                </ul>
              </li>
              <li v-if="!$root.currentUser"><router-link to="/login"><i class="icon-lock"></i> {{ $t('header.top.login') }}</router-link></li>
            <li v-if="$root.currentUser"><router-link to="/account/"><i class="icon-person_outline"></i>{{ $t('header.top.myAccount') }}</router-link></li>
            <li v-if="$root.currentUser"><a @click="logout()" href="javascript:void(0)">{{ $t('header.top.logout') }}</a></li>

              <!-- <li class="nauth"><a class="open-login" href="#" @click="showModal = true"><i class="icon-lock"></i> </a></li> -->
              <!-- <li><i class="icon-language"></i></li> -->
              <li><a class="language" :class="{'current-language': 'pt' == $i18n.locale }" href="javascript:void(0)" @click="changeLanguage('pt')"><img src="./assets/img/br.png" height="18"></a></li><!-- {{ $t('header.top.portuguese') }}-->
              <li><a class="language" :class="{'current-language': 'en' == $i18n.locale }" href="javascript:void(0)" @click="changeLanguage('en')"><img src="./assets/img/us.png" height="18"></a></li><!-- {{ $t('header.top.english') }} -->
            </ul>
        </div>
        <div class="header-content">
          <div class="titles">
              <h1 class="title" v-if="$route.name == 'Home'">{{ $t('pages.home.title') }}</h1>
              <h2 class="title" v-if="$route.name != 'Home' && $route.name != 'Products'">{{ $t('titles.'+$route.name) }}</h2>
              <!-- <h2 class="title" v-if="$route.name == 'Products' && $route.params.category && !$route.params.product">{{ $t('titles.products.'+$route.params.category) }}</h2> -->
              <h2 class="title" v-if="$route.name == 'Products' && !$route.params.category">{{ $t('titles.products.default') }}</h2>
              <h2 class="title" v-if="$route.name == 'Products' && $route.params.category">{{ $root.title }}</h2>
          </div>
          <!-- <template v-if="$route.name == 'Home'">
            <router-view/>
          </template> -->
        </div>
      </div>
    </header>
    <div class="main light"> <!--  v-if="$route.name != 'Home'">-->
      <router-view/>
    </div>

<subscribe></subscribe>
    <footer-common></footer-common>
    <footer-address></footer-address>
          <!-- <modal v-if="showModal" :classes="'xs'" @close="showModal = false">
            <h3 slot="header">Cadastre-se</h3>
            <div slot="body">
              <h4>Com seu perfil nas redes sociais</h4>
              <div class="buttons full margin">
                <button class="button full google"><span></span>Google</button>
                <button class="button full facebook">Facebook</button>
                <button class="button full twitter">Twitter</button>
              </div>
              <span class="divider">ou</span>
              <div class="form-input">
                <label for="name">Nome</label>
                <input type="name" id="name">
              </div>
              <div class="form-input">
                <label for="email">E-mail</label>
                <input type="email" id="email" v-model="form.email">
              </div>
              <div class="form-input">
                <label for="password">Senha</label>
                <input type="password" id="password" v-model="form.password">
              </div>
              <div class="input-checkbox">
                <input type="checkbox" id="newsletter">
                <span></span>
                <label for="newsletter">Receber novidades e ofertas exclusivas por e-mail.</label>
              </div>
            </div>
            <template slot="footer">
            <button class="button full" @click="signup()">Cadastre-se</button>
            <a href="javascript:void(0)" class="text-center">Já é cadastrado? Acesse sua conta</a>
            </template>
          </modal> -->
  </div>
</template>

<script>
// @ is an alias to /src
  import Parallax from 'vue-parallaxy'

import Subscribe from '@/components/Subscribe.vue'
import FooterCommon from '@/components/FooterCommon.vue'
import FooterAddress from '@/components/FooterAddress.vue'
import jsFunction from '@/helpers/jsFunctions.js'
const axios = require('axios').default;

export default {
  name: 'HomeView',
  components: {
      Parallax,
   Subscribe,
   FooterCommon,
   FooterAddress,
  //  Modal
  },
  data(){
    return {
        view: false,
        viewProduct: false,
        currentImage: 0,
        lastImage: 0,
      showModal: false,
      heightSlides: '100vh',
      money: null,
      amount: 1,
      currency: {
        USD: {},
        EUR: {}
      },
      currencyDate: "",
      currencySymbol:{
        USD: '$',
        EUR: '€',
        BRL: 'R$',
      },
      currencyUsd: {
        BRL: {},
        EUR: {}
      },
      currencyUsdDate: "",
      currencyLoaded: false,
    }
  },
    created(){
              this.loadCategories()

    },
  mounted(){
        this.rates('USD');
        this.rates('EUR', true);
        this.ratesUsd();
        this.changeSlide()
  },
  computed: {
    images() {
        return [
            'https://firebasestorage.googleapis.com/v0/b/campechebr.appspot.com/o/slides%2Ffish-3062034.jpg?alt=media&token=ff80a790-4eb3-4503-a1cd-ed47beee0fc6',
            'https://firebasestorage.googleapis.com/v0/b/campechebr.appspot.com/o/slides%2Fthe-fishermen-3039591.jpg?alt=media&token=143f9c3c-4049-40ae-9f73-d4d21758f916',
            'https://firebasestorage.googleapis.com/v0/b/campechebr.appspot.com/o/slides%2Ffish-boat-3062036.jpg?alt=media&token=64ef85aa-dc12-42e2-ba08-9b91d36bcb6b'
            // require("./assets/img/africafishing-slide-QK69-superJumbo.jpg"),
            // require("./assets/img/africafishing-slide-9414-superJumbo.jpg"),
        ];
    }
  },
  watch: {
      '$route': {
          deep: true,
          immediate: true,
          handler(){
              this.view = false
          } 
      }
  },
  methods: {
      changeSlide(){
          let total = 2  //this.images.length-1
          if(total > 1){
            if(this.currentImage == 0){
                this.lastImage = total
            }
            else{
                this.lastImage = this.currentImage -1
            }
            setTimeout(() => {
                if(this.currentImage == 2){
                    this.currentImage = 0
                }
                else{
                    this.currentImage++
                }
                this.changeSlide()
            },7500)
          }
      },
      loadCategories(){
           this.$root.categories.loading = true
            this.$db.collection("categories").where('status', '==', true).onSnapshot(snap => {
            let collection = {}
            snap.forEach(doc => {
                let data = doc.data();
                data.id = doc.id
                collection[doc.id] = data
            })
            this.$root.categories.data = collection
            this.$root.categories.loading = false
            }) 
      },
      logout(){
            this.$firebase.auth().signOut().then(() => {
                this.$root.currentUser = null
                this.$router.push({path: '/login'})
            })
        },
    loadLang(){ // Not uses
      axios.get("http://ip-api.com/json").then((response) => {
          if (response.data.countryCode == 'BR') {
              this.$i18n.locale  = 'pt'
          }
      });
    },
    changeLanguage(lang){
      this.$i18n.locale = lang
      this.$root.localeChanges++
      jsFunction.createCookie('lang', this.$i18n.locale, 180)
        //   this.$router.push({path: '/'})
    },
    rates (target) {
      axios.get('https://economia.awesomeapi.com.br/json/daily/'+target+'-BRL/1').then((response) => {
        this.currency[target] = response.data;
        this.currencyDate = response.data[0].create_date

        if(target == 'USD')
        this.$root.currencyRate.USDtoBRL = response.data[0].ask //parseFloat(1/response.data[0].ask)
        if(target == 'EUR')
        this.$root.currencyRate.EURtoBRL = response.data[0].ask//parseFloat(1/response.data[0].ask)
       
      })
      .catch((error) => {
        console.log(error);
      });
    },
    ratesUsd () {
      axios.get('https://api.exchangeratesapi.io/latest?base=USD').then((response) => {
        this.currencyUsd.BRL.ask = (1/response.data.rates.BRL).toFixed(4)
        this.currencyUsd.EUR.ask = (1/response.data.rates.EUR).toFixed(4)
        this.currencyUsdDate = response.data.date
        this.$root.currencyRate.BRLtoUSD = response.data.rates.BRL
        this.$root.currencyRate.EURtoUSD = response.data.rates.EUR
        this.currencyLoaded = true
        
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }
}
</script>

<style lang="scss">
@import './assets/sass/font.scss';
@import './assets/sass/main.scss';
</style>
