<template>
    <div class="container single-content admin">

    <div class="dashboard">
        <h1>Acesso restrito.</h1>
        <div class="tabs">
            <router-link to="/account/admin/products">Produtos</router-link>
            <router-link to="/account/admin/categories">Categorias</router-link>
            <router-link to="/account/admin/about">Sobre</router-link>
            <router-link to="/account/admin/messages">Mensagens</router-link>
            <router-link to="/account/admin/inscritos">Cadastros Newsletter</router-link>
        </div>
        <router-view></router-view>
    </div>
    </div>
</template>
<script>
export default {
    name: "AccountHome",
    data(){
        return {
            currentUser: {}
        }
    },
    mounted(){
        this.currentUser = this.$firebase.auth().currentUser;
    },
    methods: {
        logout(){
            this.$firebase.auth().signOut().then(() => {
                this.$root.currentUser = null
                this.$router.push({path: '/login'})
            })
        }
    }
}
</script>