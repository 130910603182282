<template>
    <div>
        <div class="content-header">
            <div class="filters">
            </div>
            <button class="button green" @click="add()">Adicionar</button>
        </div>
        <h3 v-if="loading">Carregando</h3>
        <table v-if="!loading">
            <thead>
                <tr><th>Nome</th><th>Categoria</th><th>Status</th><th>Origem</th><th>Tipos</th><th></th></tr>
            </thead>
            <tbody>
                <tr v-for="(product, index) in productList" :key="index">
                    <td>{{ product.name }}</td>
                    <td>{{ product.category }}</td>
                    <td>{{ product.status }}</td>
                    <td>{{ product.countryOrigin }}</td>
                    <td>{{ product.types }}</td>
                    <td>
                        <div class="buttons-s">
                            <button class="button button-small link" style="margin-right: 5px" @click="edit(product.id)"><i class="icon-mode_edit"></i></button>
                            <button class="button button-small link red" @click="remove(product.id)"><i class="icon-delete_forever"></i></button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <product-form v-if="showForm" :id="documentId" @close="closeForm" :categories="categories">

        </product-form>
    </div>
</template>
<script>
import ProductForm from './ProductForm.vue'
export default {
    name: "ProductList",
    components: {
        ProductForm
    },
    data(){
        return {
            showForm: false,
            list: [],
            loading: true,
            documentId: null,
            categories: {
                loading: false,
                data: {}
            }
        }
    },
    mounted(){
        this.loadCategories()
    },
    methods: {
        add(){
            this.documentId = null
            this.showForm = true
        },
        edit(docId){
            this.documentId = docId
            this.showForm = true
        },
        remove(docId){
            this.$db.collection("products").doc(docId).delete().then(()=>{
                this.$Message({message: 'Produto removido com sucesso', duration: 3000})
            })
        },
        loadCategories(){
            this.categories.loading = true
            this.$db.collection("categories").onSnapshot(snap => {
                let collection = {}
                snap.forEach(doc => {
                    let data = doc.data();
                    data.id = doc.id
                    collection[doc.id] = data
                })
                this.categories.data = collection
                this.categories.loading = false
                this.loadProducts()
            }) 
        },
        loadProducts(){
            this.loading = true
            this.$db.collection("products").onSnapshot(snap => {
                let collection = []
                snap.forEach(doc => {
                    let data = doc.data();
                    data.id = doc.id
                    collection.push(data)
                })
                this.list = collection
                this.loading = false
            }) 
        },
        
        closeForm(){
            this.showForm = false
            this.documentId = null
        }
    },
    computed: {
        productList(){
            let products = []
            this.list.forEach((product) => {
                let label = []
                product.types.forEach((type) => {
                    label.push(this.$t('common.productType.'+type.name))
                })
                let types = label.join(', ')
                let row = {
                    id: product.id,
                    name: product.name[this.$i18n.locale],
                    category: this.categories.data[product.category].name[this.$i18n.locale],
                    status: (product.status) ? this.$t('common.status.active') : this.$t('common.status.inactive'),
                    types: types,
                    countryOrigin: (product.countryOrigin) ? product.countryOrigin.join(',') : ''
                }
                products.push(row)
                this.loading = false
            })
            return products
        }
    }
}
</script>
<style lang="scss">
    .content-header{
        display: flex;
        .filters{
            flex: 1 1 90%;
        }
        .button{
            flex: 1 1 10%;
        }
    }
</style>