<template>
    <footer>
        <div class="container">
            <div class="">
                <img src="../assets/img/logo-v-white.svg" height="100px" alt="">

            </div>
            <ul class="pages"> 
                <li><router-link to="/">{{ $t('footer.common.nav.home') }}</router-link></li>
                <li><router-link :to="'/'+$t('routes.about')">{{ $t('footer.common.nav.about') }}</router-link></li>
                <li><router-link :to="'/'+$t('routes.contact')">{{ $t('footer.common.nav.contact') }}</router-link></li>
            </ul>
            <ul class="pages">
                <li><router-link :to="'/'+$t('routes.products')">{{ $t('footer.common.nav.products') }}</router-link></li>
                <li v-for="(category, id) in $root.categories.data" :key="id"><router-link :to="'/products/'+category.uri[$i18n.locale]">{{ category.name[$i18n.locale] }}</router-link></li>
                <!-- <li>{{ $t('footer.common.nav.social') }}</li>
                <li><a class="" href="#">Facebook</a></li>
                <li><a href="#">Linkedin</a></li> -->
            </ul>
        </div>
    </footer>
</template>
<script>
export default {
    name: "FooterCommon"
}
</script>