<template>
  <div class="container single-content">
    <div class="about" v-html="text"></div>
  </div>
</template>
<script>
export default {
  name: "AboutView",
  title: "Quem somos",
  data(){
      return {
          loading: false,
          form: {
              pt: null,
            en: null
          }
      }
  },
  mounted(){
      this.loadAbout()
  },
  computed: {
      text(){
          return this.form[this.$i18n.locale]
      }
  },
  methods: {
      loadAbout(){
            this.loading = true
            this.$db.collection("about").onSnapshot(snap => {
            snap.forEach(doc => {
                let data = doc.data();
                this.form.pt = data.pt
                this.form.en = data.en
            })
            this.loading = false
            }) 
        },
  }
}
</script>