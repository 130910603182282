<template>
    <div>
        <modal v-if="show" @close="show = false" :classes="'lg'">
            <h3 slot="header">Categoria</h3>
            <div slot="body">
                <div class="row">
                    <div class="col-6">
                        <div class="form-input">
                            <label for="name">Nome (português)</label>
                            <input v-model="form.name.pt" type="text" id="name">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-input">
                            <label for="name">Nome (inglês</label>
                            <input v-model="form.name.en" type="text" id="name">
                        </div>
                    </div>
                </div>
               
                <div class="row">
                    <div class="col-12">
                        <div class="form-input">
                            <label for="name">Status</label>
                            <div class="input-radio">
                                <input type="checkbox" id="status-form" value="1" v-model="form.status">
                                <span></span>
                                <label for="status-form">{{ status }}</label>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
            <template slot="footer">
                <button class="button red" @click="show = false">Cancelar</button>
            <button class="button green" @click="save()">Salvar</button>
            </template>
          </modal>
    </div>
</template>
<script>
import Modal from '@/components/Modal.vue'
import jsFunction from '@/helpers/jsFunctions.js'
export default {
    name: "ProductForm",
    components: {
        Modal,
    },
    data(){
        return {
            show: true,
            form: {
                name: {
                    pt: '',
                    en: ''
                },
                uri: {
                    pt: this.ptSlugfy,
                    en: this.enSlugfy
                }
            }
        }
    },
    props: {
        id: {
            required: false,
            type: String
        }
    },
    watch: {
        'show'(nV){
            if(!nV){
                this.$emit('close')
            }
        },
    },
    mounted(){
        if(this.id != null){
            this.$db.collection("categories").doc(this.id).get().then(snap => {
                    let data = snap.data();
                    this.form = data
            })
        }
    },
    computed: {
        status(){
            return (this.form.status) ? 'Ativo' : 'Inativo'
        }
    },
    methods: {
        save(){
            this.form.uri.pt = jsFunction.slugsTidy(this.form.name.pt)
            this.form.uri.en = jsFunction.slugsTidy(this.form.name.en)
            this.form.date = this.$moment().format('x')
            if(this.id){
                this.$db.collection("categories").doc(this.id).set(this.form).then(
                    result => {
                        if(result){
                            this.$Message({message: 'Categoria atualizada com sucesso', duration: 3000})
                        }
                    },
                    err => {
                        this.$Message({message: 'Erro ao salvar: ' + err, duration: 3000})
                    }
                );
            }
            else{
                this.$db.collection("categories").add(this.form).then(
                    result => {
                        if(result){
                            this.$Message({message: 'Categoria adicionada com sucesso', duration: 3000})
                        }
                    },
                    err => {
                        this.$Message({message: 'Erro ao salvar: ' + err, duration: 3000})
                    }
                );
            }
        }
    }
    
}
</script>