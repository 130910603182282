<template>
    <div class="subscribe"> 
        <div class="container">
            <h3 data-translate="subscribe.subscribe|html">{{ $t('subscribe.title') }}</h3>
            <form>
                <input type="text" v-model="form.name" :placeholder="placeholderName">
                <input type="email" v-model="form.email" :placeholder="placeholderEmail">
                 <ul class="errors" v-if="trySubscribe">
                    <li v-show="!validation.name">Name cannot be empty.</li>
                    <li v-show="!validation.email">
                    Please provide a valid email address.
                    </li>
                </ul>
                <button class="button" type="button" :disabled="form.name.length < 3 && form.email.length < 3" @click="subscribe()"><i class="icon-send"></i></button>
            </form>
            <div class="messages" id="subscribeReturn">
            </div>
            <p><i class="icon-info"></i> <span>{{ $t('subscribe.note')}}</span></p>
        </div>
    </div>
</template>
<script>


export default {
    name: "SubscribeComponent",
    data(){
        return {
            form: {
                name: '' ,
                email: ''
            },
            trySubscribe: false
        }
    },
    methods: {
        subscribe(){
            if(this.isValid){
                this.try = false
                this.$db.collection("subscribes").where("email", "==", this.form.email).get().then(snap => {
                    let count = 0
                    snap.forEach(() => {
                        count++
                    })
                    if(count > 0){
                                this.$Message({message: 'Você já está inscrito!', duration: 3000})
                    }
                    else {
                        this.form.createdAt = this.$moment().format('X')
                        this.form.status = 1
                        this.$db.collection("subscribes").add(this.form).then(
                            result => {
                                if(result){
                                    this.$Message({message: 'Inscrito com sucesso', duration: 3000})
                                }
                            },
                            err => {
                                this.$Message({message: 'Erro ao salvar: ' + err, duration: 3000})
                            }
                        );
                    }
                })
            }
            else{
                this.trySubscribe = true
            }
        }
    },
    computed: {
        validation() {
            return {
                name: this.form.name.trim().length > 3,
                email: (this.form.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
            }
        },
        isValid() {
            var validation = this.validation;
            return Object.keys(validation).every(function(key) {
            return validation[key];
            });
        },
        placeholderName(){
            return this.$t('subscribe.input_name')
        },
        placeholderEmail(){
            return this.$t('subscribe.input_email')
        } 
    }
}
</script>