<template>
    <div class="product-type">
        <div class="row">
            <div class="col-3">
                <div class="form-input">
                    <label for="name">Tipo</label>
                    <select v-model="form.name" class="select">
                        <option v-for="(type, t) in orderedTypes" :value="type.value" :key="t">{{ type.label }}</option>
                    </select>
                </div>
            </div>
 
            <!-- <div class="col-3">
                <div class="form-input">
                    <label for="name">Preço</label>
                    <input v-model="form.price.value" type="number" id="name">
                </div>
            </div>
            <div class="col-3">
                <div class="form-input">
                    <label for="name">Base</label>
                    <select v-model="form.price.base" class="select">
                        <option v-for="(type, t) in assets.bases" :value="type.value" :key="t">{{ type.label }}</option>
                    </select>
                </div>
            </div> -->
            <!-- <div class="col-3">
                <div class="form-input">
                    <label for="name">Métrica</label>
                    <select v-model="form.price.metric" class="select">
                        <option v-for="(type, t) in assets.metrics" :value="type.value" :key="t">{{ type.label }}</option>
                    </select>
                </div>
            </div> -->
             <div class="col-3">
                <div class="form-input">
                    <label for="name">Foto</label>
                    <upload :url="form.url" @change-url="updateURL" @update-url="form.url = $event"></upload>
                </div>
            </div>
             <div class="col-3">
                <div class="form-input">
                    <label for="sku">SKU</label>
                    <input v-model="form.sku" type="sku" id="sku">
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import _ from 'lodash';
import Upload from '@/components/Upload.vue'
export default {
    name: "TypeComponent",
    components: {
        Upload
    },
    data(){
        return {
            form: {
                name: "",
                price: {
                    base: "USD",
                    metric: "",
                    value: 0
                },
                sku: "",
                url: ""
            },
            assets: {
                 typeNames: [
                {
                    label: "Inteiro",
                    value: "entire"
                },
                 {
                    label: "Anéis",
                    value: "rings"
                },
                 {
                    label: "Filé",
                    value: "fillet"
                },
                 {
                    label: "Lombo",
                    value: "loin"
                },
                 {
                    label: "Tubo",
                    value: "tube"
                },
                 {
                    label: "Tentaculos",
                    value: "tentacles"
                },
                {
                    label: "HGT",
                    value: "hgt"
                },
                 {
                    label: "Postas",
                    value: "slices"
                },
                {
                    label: "Porções",
                    value: "portions"
                },
                {
                    label: "Eviscerado",
                    value: "eviscerated"
                },
                {
                    label: "Eviscerado sem cabeça",
                    value: "gutted_without_a_head"
                },
                {
                    label: "Filé espalmado com pele",
                    value: "fillet_flattened_with_skin"
                },
                {
                    label: "Filé pedaço",
                    value: "fillet_piece"
                },
                {
                    label: "Lombo pedaço com pele",
                    value: "loin_piece_with_skin"
                },
                {
                    label: "Lombo pedaço sem pele",
                    value: "loin_piece_without_skin"
                },
                {
                    label: "Cubos",
                    value: "cubes"
                },
                {
                    label: "Descascado eviscerado",
                    value: "peeled_and_gutted"
                },
                {
                    label: "Descascado eviscerado cozido",
                    value: "peeled_gutted_cooked"
                },
                {
                    label: "Sem pele não aparado",
                    value: "without_untrimmed_skin"
                },
                {
                    label: "Bastonetes",
                    value: "rods"
                },
                {
                    label: "HG",
                    value: "hg"
                },
                {
                    label: "Cauda",
                    value: "tail"
                },
                {
                    label: "Meia concha",
                    value: "half_shell"
                },
                {
                    label: "Desconchada",
                    value: "disheveled"
                }


            ],
            bases: [
                {
                    label: "Dólar",
                    value: "USD"
                },
                 {
                    label: "Euro",
                    value: "EUR"
                },
                 {
                    label: "Real",
                    value: "BRL"
                }
            ],
            metrics: [
                {
                    label: "quilo",
                    value: "kg"
                },
                {
                    label: "libra",
                    value: "lb"
                }
            ]
            }
           
        }
    },
    props: {
        index: {
            type: Number,
            required: true
        },
        callback: {
            type: Function,
            required: true
        },
        initial: {
            type: Object,
            required: false
        }
    },
    watch: {
        form: {
            deep: true,
            handler(){
                this.callback(this.index, this.form)
            }
        }
    },
    mounted(){
        if(Object.keys(this.initial).length){
            this.form = this.initial
        }
    },
    computed: {
        orderedTypes() {
            return _.orderBy(this.assets.typeNames, 'label')
        }
    },
  
    methods: {
        updateURL(e) {
            this.form.url = e;
        }
    }
}
</script>