<template>
    <div class="container single-content" :class="{'grid-page': !$route.params.product && $route.name == 'Products'}">
        <div class="products" v-show="!loading">
            <template v-if="!$route.params.product">
                <div class="search-bar">
                    <input class="search" type="search" v-model="keyword" :placeholder="placeholderInput"><i class="icon-search"></i>
                    <select v-model="category" class="category-list">
                        <option value="">{{ $t('pages.products.category.all') }}</option>
                        <option v-for="(category, id) in $root.categories.data" :key="id" :value="category.uri[$i18n.locale]">{{ category.name[$i18n.locale] }}</option>
                    </select>
                </div>
                <div class="list" v-if="searchList.length">
                    <product-card v-for="(product,k) in searchList" :key="k" :product="product"></product-card>
                </div>
            <div class="empty" v-if="!searchList.length && keyword != '' && category == ''">
                <h3>{{$t('pages.products.empty.title')}}</h3>
                <p v-html="$t('pages.products.empty.term', {keyword: keyword})"></p>
            </div>
            <div class="empty" v-if="!searchList.length && keyword == '' && category == ''">
                <h3>{{$t('pages.products.empty.title')}}</h3>
                <p v-html="$t('pages.products.empty.none')"></p>
            </div>
            <div class="empty" v-if="!searchList.length && keyword == '' && category != ''">
                <h3>{{$t('pages.products.empty.title')}}</h3>
                <p v-html="$t('pages.products.empty.category', {category: category})"></p>
            </div>
            <div class="empty" v-if="!searchList.length && keyword != '' && category != ''">
                <h3>{{$t('pages.products.empty.title')}}</h3>
                <p v-html="$t('pages.products.empty.category_keyword', {category: category, keyword: keyword})"></p>
            </div>
            </template>
            <product v-if="$route.params.product && !loading" :list="list"></product>
        </div>
         <template v-if="loading">
            <div class="flex">
                <h3 class="text-center">{{ $t('common.loading') }} <br><br> <img src="../assets/img/preloader.svg" alt="preloader"></h3>
                
            </div>
            
        </template>
    </div>
</template>
<script>
import Product from '@/views/Product.vue'
import ProductCard from '@/components/ProductCard.vue'
export default {
    name: "ProductsView",
    components: {
        Product,
        ProductCard
    },
    data(){
        return {
            categories: {},
            list: [],
            loading: true,
            keyword: (this.$route.query.q) ? this.$route.query.q : '',
            category: (this.$route.params.category) ? this.$route.params.category : ''
        }
    },
    watch: {
        '$route.params.category'(nV){
            if(!this.$route.params.product){
                this.category = (typeof nV != 'undefined' && nV != null) ? nV : ''
                this.loadCurrentCategory()
            }
        },
        '$route.params.product'(nV){
            if(!nV){
                this.loadCurrentCategory()
            }
        },
        'category'(nV){
            const query = (this.keyword) ? {q: this.keyword} : {}
            const path = '/'+ this.$t('routes.products') + '/' + nV
            if (this.$route.path !== path && query !== this.$route.query){
                let params = {}
                if(nV){
                    params.category = nV
                    this.$router.push({path: '/'+ this.$t('routes.products') + '/' + nV, params: params, query: query})
                }
                if(!nV){
                    this.loadCurrentCategory()
                }
            }
            else{
                this.loadCurrentCategory()
            }
        },
        '$i18n.locale'(nV, oV){
            let uriCategory = ''
            if(this.$route.params.category){
               
               
                for(let cat in this.$root.categories.data){
                    
                    if(this.$root.categories.data[cat].uri[oV] == this.$route.params.category){
                        this.$route.params.category = this.$root.categories.data[cat].uri[nV]
                        uriCategory = '/' + this.$root.categories.data[cat].uri[nV]
                    }
                }
            }
            let uriProduct = ''
            if(this.$route.params.product){
                
                this.list.forEach((product) => {
                    
                    if(product.uri[oV] == this.$route.params.product){
                        this.$route.params.product ==product.uri[nV]
                        uriProduct = '/' + product.uri[nV]
                    }
                })
            }
            let router = {path: '/'+ this.$t('routes.products') + uriCategory + uriProduct, params: this.$route.params, query: this.$route.query}
            this.$router.push(router)
            if(uriProduct != ''){
                this.loadProducts(router)
            }
            else{
                this.loadProducts()
            }
        },
        'keyword'(newValue){
            if(newValue)
                this.$router.replace({ query: { q: this.keyword } })
            else
                this.$router.replace({ query: {  } })
        }
    },
    mounted() {
        
        // this.loadProduct()
        this.loadProducts()
        this.loadCurrentCategory()
    },
    computed: {
        searchList() {
           
            
          return this.list.filter((product) => {
              if(this.$route.query.q != '' || this.$route.params.category != '') {
                return this.keyword.toLowerCase().split(' ').every(v => 
                    product.name[this.$i18n.locale].toLowerCase().includes(v) && (this.category == product.categoryUri || this.category == '')
                );
              }
                return true
          });
        },
        placeholderInput(){
            return this.$t('pages.products.placeholder')
        }
    },
    methods: {
        loadCurrentCategory(){
               for(let cat in this.$root.categories.data){
                   if(this.$root.categories.data[cat].uri[this.$i18n.locale] == this.$route.params.category){
                       this.$root.title = this.$root.categories.data[cat].name[this.$i18n.locale]
                   }
               }
            //    this.loadProducts()
        },
        loadProducts(router = false){
            this.loading = true
            this.$db.collection("products").where('status', '==', true).onSnapshot(snap => {
                let collection = []
                snap.forEach(doc => {
                    let data = doc.data();
                    data.categoryUri = this.$root.categories.data[data.category].uri[this.$i18n.locale]
                    data.categoryName = this.$root.categories.data[data.category].name[this.$i18n.locale]
                    collection.push(data)
                })

                collection.sort((a, b) => a.name[this.$i18n.locale].localeCompare(b.name[this.$i18n.locale]))

                this.list = collection
                this.loading = false
                if(router != false)
                this.$router.push(router)
            }) 
        }
    }
}
</script>
<style lang="scss">
.card .icons{
    justify-content: center;
}
</style>