<template>
     <div class="card">
         <img v-for="type in this.product.types" :key="type.name" :src="type.url" :alt="type.name" width="0" height="0" v-show="false">
        <figure><img :src="activeImage"></figure>
        <div class="card-content">
            <h3>{{ product.name[$i18n.locale] }}</h3>
            <h5 v-if="product.scientificName"><em>{{ product.scientificName }}</em></h5>
            <label>{{ $tc('common.location', productCountries.length)}}: 
                <template v-if="showFlag">
                    <div class="tags" v-if="productCountries.length">
                        <div class="tag" v-for="(flag,i) in productCountries" :key="i"><img :src="getImgUrl(flag.alpha2)" height="22" :alt="flag.name" style="margin: 2px 5px -6px 0px">{{  flag.name }}</div>
                    </div>
                    <strong v-else><i>{{ $t('common.undefined') }}</i></strong>
                </template>
                <template v-if="!showFlag">
                    <strong v-if="productCountries">{{ productCountries }}</strong>
                    <strong v-else><i>{{ $t('common.undefined') }}</i></strong>
                </template> 
            </label>
            <div class="tags">
                <label>{{ $tc('common.presentation', product.types.length )}}: </label>
                <div class="tag" v-for="(type, i) in product.types" :key="i" @click="selected = type.sku" :class="{'selected': selected == type.sku}">
                    {{ $t('common.productType.'+type.name) }}
                </div>
            </div>

            <router-link class="button" :to="'/'+$t('routes.products') + '/' +product.categoryUri+'/'+productUri">{{ $t('common.details')}}</router-link>
        </div>
    </div>
</template>
<script>
import country_en from '../assets/world-countries/data/en/world.json'
import country_pt from '../assets/world-countries/data/pt/world.json'
export default {
    name: 'ProductCard',
    data(){
        return {
            showFlag: true,
            country: {
                pt: country_pt,
                en: country_en
            },
            img: '',
            selected: {}
        }
    },
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    mounted(){
        this.selected = this.product.types[0].sku
    },
    watch: {
        product: {
            deep: true,
            handler(){
                this.selected = this.product.types[0].sku
            }
        }
    },
    methods: {
         getImgUrl(pic) {
            return require('../assets/flags/'+pic+'.png')
        }
    },
    computed:{
        activeImage(){
            let url = ''
            this.product.types.forEach((item) => {
                if(item.sku == this.selected){
                    url = item.url
                }
            })
            if(url == ''){
                this.product.types.every((item) => {
                    if(item.url){
                        url = item.url
                    }
                })
            }
            return url
        },
        productCountries(){
            let list = [];
            let countryList = null;
            if(this.product.countryOrigin)
            this.product.countryOrigin.forEach((country) => {
                if(country != null){
                    countryList = this.country[this.$i18n.locale].filter((item) => {
                        if(item){
                            return item.alpha2.trim() == country.trim().toLowerCase()
                        }
                    })
                    countryList.forEach((item) =>{
                        if(item && !this.showFlag)
                            list.push(item.name)
                        if(item && this.showFlag)
                            list.push(item)
                    })
                }
            })
            if(this.showFlag){
                return list;
            }
            return list.join(', ')
            
        },
        productUri(){
            let uri = ''
            if(typeof this.product.uri === 'string'){
               uri = this.product.uri
            }
            else{
                uri = this.product.uri[this.$i18n.locale]
            }
            return uri
        }
    }

}
</script>
