<template>
  <div class="card-page">
    <div slot="body">
        <h4>Novos cadastros estão suspensos temporariamente.</h4>
        <!-- <h4>Com seu perfil nas redes sociais</h4>
        <div class="buttons full margin">
        <button class="button full google"><span></span>Google</button>
        <button class="button full facebook">Facebook</button>
        <button class="button full twitter">Twitter</button>
        </div>
        <span class="divider">ou</span> -->
        <!-- <div class="form-input">
            <label for="name">{{ $t("pages.signup.name") }}</label>
            <input type="name" id="name" v-model="form.name">
        </div>
        <div class="form-input">
            <label for="email">{{ $t("pages.signup.email") }}</label>
            <input type="email" id="email" v-model="form.email">
        </div>
        <div class="form-input">
            <label for="password">{{ $t("pages.signup.password") }}</label>
            <input type="password" id="password" v-model="form.password">
        </div>
        <div class="input-checkbox">
            <input type="checkbox" id="newsletter" v-model="subscribe">
            <span></span>
            <label for="newsletter">{{ $t("pages.signup.newsletter") }}</label>
        </div> -->
    </div>
    <button class="button full" @click="signup()">{{ $t("pages.signup.signup") }}</button>
    <router-link class="button white full" to="/login">{{ $t("pages.signup.alreadyRegistered") }}</router-link>
            
  </div>
</template>

<script>
export default {
    name: "SignupView",
    data(){
        return {
            subscribe: true,
            form: {
                name: '',
                email: '',
                password: ''
            },
        }
    },
    methods: {
        signup(){
            alert('Cadastros de usuários estão suspensos temporariamente.')
            // this.$firebase.auth().createUserWithEmailAndPassword(this.form.email, this.form.password).then(
            // () => {
            //     var user = this.$firebase.auth().currentUser;
            //     user.updateProfile({
            //         displayName: this.form.name
            //     }).then(() => {
            //         console.log('Displayname updated.')
            //     }, (error) => {
            //         console.log(error)
            //     });        
            //     if(this.subscribeForm){
            //         let subscribeForm = Object.assign({}, this.form)
            //         subscribeForm.createdAt = this.$moment().format('X')
            //         subscribeForm.status = 1
            //         this.$db.collection("subscribes").add(subscribeForm).then(
            //             result => {
            //                 console.log(result)
            //             },
            //             err => {
            //                 console.log(err)
            //             }
            //         )
            //     }
            //     this.$root.currentUser = this.$firebase.auth().currentUser.email
            //     this.$router.push({path: '/account/'})
            // },
            // (err) => {
            // alert('Algo deu errado. ' + err.message)
            // })
        },
    }
}
</script>