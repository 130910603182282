<template>
    <div class="boxes">
        <div class="box">
            <figure>
                <img src="https://firebasestorage.googleapis.com/v0/b/campechebr.appspot.com/o/slides%2Fimports.jpg?alt=media&token=53a2242c-ebe3-473d-9fb1-c8d0c2b65d46" alt="Imports">
            </figure>
            <div class="box-info">
                <h1>{{ $t('pages.home.box.imports.title') }}</h1>
                <p>{{ $t('pages.home.box.imports.subtitle') }}</p>
                <router-link class="button" :to="`/${$t('routes.products')}/${$t('routes.imports')}`">{{ $t('pages.home.box.button') }}</router-link>
            </div>
        </div>
        <div class="box">
            <figure>
                <img src="https://firebasestorage.googleapis.com/v0/b/campechebr.appspot.com/o/slides%2Fafrica-fishing-9414.jpg?alt=media&token=a5183e09-d03e-41fd-867e-5d0b1c05e656" alt="Exports">
            </figure>
            <div class="box-info">
                <h1>{{ $t('pages.home.box.exports.title') }}</h1>
                <p>{{ $t('pages.home.box.exports.subtitle') }}</p>
                <router-link class="button" :to="`/${$t('routes.products')}/${$t('routes.exports')}`">{{ $t('pages.home.box.button') }}</router-link>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'HomeView',
}
</script>
