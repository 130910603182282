<template>
    <div>
        <div class="content-header">
            <div class="filters">
            </div>
            <button class="button green" @click="add()">Adicionar</button>
        </div>
        <table>
            <thead>
                <tr><th>Data</th><th>Nome</th><th>E-mail</th><th>Assunto</th><th></th></tr>
            </thead>
            <tbody v-for="(item, index) in itemsList" :key="index">
                
                <tr>
                    <td>{{ item.date }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.phone }}</td>
                    <td>{{ item.subject }}</td>
                    <td>
                        <div class="buttons-s">
                            <button class="button button-small link" @click="item.show = true" style="margin-right: 5px"><i class="icon-mode_edit"></i></button>
                        </div>
                    </td>
                </tr>
                <tr v-if="item.show">
                    <td colspan="6">
                        <button class="button button-small" @click="item.show = false">Fechar</button>
                        <pre style="width: inherit; padding: 10px; text-align: left; white-space: break-spaces; line-height: 1.25">{{ item.message }}</pre>
                    </td>
                </tr>
            </tbody>
        </table>
            
        
    </div>
</template>
<script>
import moment from 'moment'
export default {
    name: "MessageList",
    components: {
    },
    data(){
        return {
            itemsList: [],
            showForm: false,
            list: [],
            loading: false,
            documentId: null
        }
    },
    mounted(){
        this.loadCategories()
    },
    methods: {
        add(){
            this.documentId = null
            this.showForm = true
        },
        edit(docId){
            this.documentId = docId
            this.showForm = true
        },
       
        loadCategories(){
            this.loading = true
            this.$db.collection("messages").onSnapshot(snap => {
            let collection = []
            snap.forEach(doc => {
                let data = doc.data();
                data.id = doc.id
                collection.push(data)
            })
            this.list = collection
            this.listing()
            }) 
        },
        listing(){
            let items = []
            this.list.forEach((item) => {
                let row = {
                    ...item,
                    dateRoot: item.date,
                    id: item.id,
                    show: false,
                    date: moment(item.date,'x').format('DD/MM/YYYY')
                    // status: (item.status) ? this.$t('common.status.active') : this.$t('common.status.inactive'),
                }
                items.push(row)
            })
            this.itemsList =  items.sort(function(a,b){
    return a.dateRoot.localeCompare(b.dateRoot);
})
this.loading = false
        },
        closeForm(){
            this.showForm = false
            this.documentId = null
        }
    },
    computed: {

    }
}
</script>
<style lang="scss">
    .content-header{
        display: flex;
        .filters{
            flex: 1 1 90%;
        }
        .button{
            flex: 1 1 10%;
        }
    }
</style>