import Vue from 'vue'
import VueI18n from 'vue-i18n'
import jsFunction from '@/helpers/jsFunctions.js'
let lang = jsFunction.readCookie('lang')
let locale = (navigator.language == 'pt-BR') ? 'pt' : 'en'
lang ? locale = lang : jsFunction.createCookie('lang', locale, 180)


Vue.use(VueI18n)
const numberFormats = {
    'en-US': {
        currency: {
            style: 'currency',
            currency: 'USD'
        }
    },
    'pt-BR': {
        currency: {
            style: 'currency',
            currency: 'BRL',
            // currencyDisplay: 'symbol'
        }
    }
}

const messages = {
    'en': {
        routes: {
            about: 'about',
            products: 'products',
            contact: 'contact',
            imports: 'imports',
            exports: 'exports'
        },
        common: {
            description: 'Description',
            loading: 'Loading...',
            favorite: 'Add in favorite list',
            addToOrder: 'Add in order',
            browseAll: 'Browse all',
            location: 'Country of origin | Countries of origin',
            undefined: 'Undefined',
            new: 'New',
            priceOnRequest: 'Price on request',
            presentation: 'Presentation | Presentations',
            details: 'Details',
            productType: {
                entire: 'Whole round',
                fillet: 'Fillet',
                loin: 'Loin',
                rings: 'Rings',
                hgt: 'HGT',
                tentacles: 'Tentacles',
                tube: 'Tubes',
                slices: 'Steaks',
                eviscerated: 'Gutted',
                gutted_without_a_head: "Gutted without a head",
                fillet_flattened_with_skin: 'Fillet flattened with skin',
                fillet_piece: 'Fillet piece',
                loin_piece_with_skin: 'Loin piece with skin',
                loin_piece_without_skin: 'Loin piece without skin',
                cubes: 'Cubes',
                peeled_and_gutted: 'Peeled and gutted',
                peeled_gutted_cooked: 'Peeled gutted cooked',
                without_untrimmed_skin: 'Without untrimmed skin',
                rods: 'Rods',
                portions: 'Portions',
                hg: 'HG',
                tail: "Tail",
                half_shell: 'Half-shell',
                disheveled: 'Disheveled'

            },
            category: {
                imports: 'Imports',
                exports: 'Exports'
            },
            status: {
                waiting: 'Waiting',
                active: 'Active',
                inactive: 'Inactive'
            }
        },
        titles: {
            About: 'About us',
            ContactUs: 'Contact us',
            Login: "Login",
            Signup: "Signup",
            LostPassword: "Lost Password",
            products: {
                default: "Products",
                imports: "World Products",
                exports: "Brazilian Products"
            }
        },
        header: {
            top: {
                base: 'American Dollar',
                at: 'at',
                login: 'Login',
                portuguese: 'Português',
                english: 'English',
                logout: 'Logout',
                myAccount: 'My Account'
            },
            nav: {
                home: "Home",
                products: "Products",
                about: "About",
                contact: "Contact Us",
                exports: "From Brazil",
                imports: "From World",
            }
        },
        subscribe: {
            title: "Subscribe",
            input_name: "Your name",
            input_email: "Your e-mail",
            note: "We never send span or share your data with others"
        },
        footer: {
            common: {
                nav: {
                    social: "Social",
                    home: "Home",
                    products: "Products",
                    about: "About",
                    contact: "Contact Us",
                }
            },
            address: {
                document: 'Brazilian Registry of Legal Entities'
            }
        },
        pages: {
            contact: {
                phone: "Phone Number",
                address: "Address",
                street: "{number} {street} st., class {class}",
                brazil: "Brazil",
                postalcode: "Postal Code",
                subjects: {
                    questions: "Questions about products, services or company",
                    order: "Order and estimate",
                    financial: "Financials",
                    others: "Others"
                },
                form: {
                    title: "Get in touch",
                    name: "Your name",
                    email: "Your e-mail",
                    phone: "Your phone number",
                    subject: "Subject",
                    message: "Your message",
                    send: "Send",
                    error: "Error",
                    required: "is required",
                    thanks: "Thank you!",
                    success: "Your message has been sent successfully. We'll return soon",
                    back: "Back"
                }
            },
            products: {
                category: {
                    all: "All products",
                    imports: "Imports",
                    exports: "Exports"
                },
                placeholder: "Search products",
                empty: {
                    title: "No products found.",
                    term: 'The term <strong>"{keyword}"</strong> did not bring any results.',
                    none: "All products are currently unavailable.",
                    category: 'The <strong>"{category}"</strong> category does not have any products registered so far.',
                    category_keyword: 'The search for <strong> "{keyword}" </strong> did not return any products in the <strong> "{category}" </strong> category.<br>Check your search term and try again.'
                }
            },
            home: {
                title: "Always looking for your best seafood option.",
                box: {
                    imports: {
                        title: "Imports",
                        subtitle: "From the world to Brazil"
                    },
                    exports: {
                        title: "Exports",
                        subtitle: "From Brazil to the world"
                    },
                    button: "See all"
                }
            },
            login: {
                email: "Email",
                password: "Password",
                lostPassword: "Lost my password",
                login: "Login",
                notRegistered: "Not registered? Click here."
            },
            signup: {
                name: "Name",
                email: "E-mail",
                password: "Password",
                newsletter: "Receive news and exclusive offers by e-mail.",
                signup: "Signup",
                alreadyRegistered: "Already registered? Login now."
            },
            lostPassword: {
                subtitle: "We send a message with instructions to update new password.",
                email: "E-mail",
                send: "Send",
                rememberPassword: "I remember my password"
            }
        },
    },
    'pt': {
        routes: {
            about: 'sobre',
            products: 'produtos',
            contact: 'contato',
            imports: 'importacao',
            exports: 'exportacao'
        },
        common: {
            description: 'Descrição',
            loading: 'Carregando...',
            addToOrder: 'Incluir no pedido',
            favorite: 'Adicionar na lista de favoritos',
            new: 'Novo',
            browseAll: 'Ver todos',
            location: 'País de origem | Países de origem',
            undefined: 'Indefinido',
            priceOnRequest: 'Preço sob consulta',
            presentation: 'Apresentação | Apresentações',
            details: 'Detalhes',
            productType: {
                entire: 'Inteiro',
                fillet: 'Filé',
                loin: 'Lombo',
                rings: 'Anéis',
                hgt: 'HGT',
                tentacles: 'Tentáculos',
                tube: 'Tubos',
                slices: 'Postas',
                eviscerated: 'Eviscerado',
                gutted_without_a_head: 'Eviscerado sem cabeça',
                fillet_flattened_with_skin: 'Filé espalmado com pele',
                fillet_piece: 'Filé pedaço',
                loin_piece_with_skin: 'Lombo pedaço com pele',
                loin_piece_without_skin: 'Lombo pedaço sem pele',
                cubes: 'Cubos',
                peeled_and_gutted: 'Descascado eviscerado',
                peeled_gutted_cooked: 'Descascado eviscerado cozido',
                without_untrimmed_skin: 'Sem pele não aparado',
                rods: 'Bastonetes',
                portions: 'Porções',
                hg: 'HG',
                tail: "Cauda",
                half_shell: 'Meia concha',
                disheveled: 'Desconchada'
            },
            category: {
                imports: 'Importação',
                exports: 'Exportação'
            },
            status: {
                waiting: 'Pendente',
                active: 'Ativo',
                inactive: 'Inativo'
            }
        },
        titles: {
            About: 'Sobre nós',
            ContactUs: 'Contato',
            Login: "Entrar",
            Signup: "Cadastre-se",
            LostPassword: "Esqueci minha senha",
            products: {
                default: "Produtos e Serviços",
                imports: "Produtos para Importação",
                exports: "Produtos para Exportação"
            }
        },
        header: {
            top: {
                base: 'Real',
                at: 'em',
                login: 'Entrar',
                portuguese: 'Português',
                english: 'English',
                logout: 'Sair',
                myAccount: 'Minha Conta'
            },
            nav: {
                home: "Home",
                products: "Produtos e Serviços",
                about: "Sobre",
                contact: "Contato",
                exports: "Exportação",
                imports: "Importação",
            }
        },
        subscribe: {
            title: "Inscreva-se",
            input_name: "Seu nome",
            input_email: "Seu email",
            note: "Nunca enviamos span ou compartilhamos seus dados com terceiros"
        },
        footer: {
            common: {
                nav: {
                    social: "Redes sociais",
                    home: "Home",
                    products: "Produtos e Serviços",
                    about: "Sobre",
                    contact: "Contato",
                }
            },
            address: {
                document: 'CNPJ'
            }
        },
        pages: {

            contact: {
                phone: "Telefone",
                address: "Endereço",
                street: "Rua {street} {number}, sala {class}",
                brazil: "Brasil",
                postalcode: "CEP",
                subjects: {
                    questions: "Perguntas sobre produtos, serviços ou sobre a empresa",
                    order: "Pedido e orçamento",
                    financial: "Financeiro",
                    others: "Outros"
                },
                form: {
                    title: "Fale conosco",
                    name: "Seu nome",
                    email: "Seu e-mail",
                    phone: "Seu número de telefone",
                    subject: "Assunto",
                    message: "Sua mensagem",
                    send: "Enviar",
                    error: "Erro",
                    required: "é obrigatório",
                    thanks: 'Obrigado!',
                    success: "Sua mensagem foi enviada com sucesso. Retornaremos em breve",
                    back: "Voltar"
                }
            },
            products: {
                category: {
                    all: "Todos os produtos e serviços",
                    imports: "Importação",
                    exports: "Exportação"
                },
                placeholder: "Buscar produto ou serviço",
                empty: {
                    title: "Nenhum produto ou serviço encontrado.",
                    term: 'O termo <strong>"{keyword}"</strong> não trouxe nenhum resultado.',
                    none: "No momento todos os produtos e serviços estão indisponíveis.",
                    category: 'A categoria <strong>"{category}"</strong> não possui nenhum produto ou serviço cadastrado até o momento.',
                    category_keyword: 'A busca por <strong>"{keyword}"</strong> não retornou nenhum produto ou serviço na categoria <strong>"{category}"</strong>.<br>Verifique o termo pesquisado e tente novamente.'
                }
            },
            home: {
                title: "Sempre em busca da sua melhor opção de pescado.",
                box: {
                    imports: {
                        title: "Importação",
                        subtitle: "Do mundo para o Brasil."
                    },
                    exports: {
                        title: "Exportação",
                        subtitle: "Do Brasil para o mundo."
                    },
                    button: "Ver todos"
                }
            },
            login: {
                email: "Email",
                password: "Senha",
                lostPassword: "Esqueci minha senha",
                login: "Entrar",
                notRegistered: "Não é cadastrado? Clique aqui."
            },
            signup: {
                name: "Nome",
                email: "Email",
                password: "Senha",
                newsletter: "Receber novidades e ofertas exclusivas por e-mail.",
                signup: "Cadastrar",
                alreadyRegistered: "Já está cadastrado? Entre agora."
            },
            lostPassword: {
                subtitle: "Enviaremos um e-mail com instruções para cadastrar uma nova senha.",
                email: "Email",
                send: "Enviar",
                rememberPassword: "Lembrei minha senha"
            }
        }
    }
}

const i18n = new VueI18n({
    numberFormats,
    fallbackLocale: 'en-US',
    locale: locale, // set locale
    messages, // set locale messages
})
export default i18n