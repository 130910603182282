import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import VTooltip from 'v-tooltip'
// import Message from 'message-tip'
import firebase from 'firebase';
// import InertiaTable from 'inertia-table'
import { VueReCaptcha } from 'vue-recaptcha-v3'


require('firebase/firestore');
const moment = require('moment'); // require
const firebaseConfig = {
    apiKey: 'AIzaSyA2VkMkNg-LUMQK1vU4QPjr5qjA9aNsrBQ',
    authDomain: 'campechebr.firebaseapp.com',
    databaseURL: 'https://campechebr.firebaseio.com',
    projectId: 'campechebr',
    storageBucket: 'campechebr.appspot.com',
    messagingSenderId: '521493369838',
};
const recaptchaConfig = {
    siteKey: '6LcRwbEZAAAAAPGmj3qk4iGzNPcpA6bbHOqsPD7l',
    secretKey: '6LcRwbEZAAAAAK1Q8zYxt2JMIwUxmGCU-hvVAjFx'
}
firebase.initializeApp(firebaseConfig);

Vue.prototype.$firebase = firebase;
Vue.prototype.$moment = moment;
Vue.prototype.$db = firebase.firestore();
Vue.config.productionTip = false
Vue.use(moment)
Vue.use(VTooltip)
// Vue.use(Message)
// Vue.use(InertiaTable);

Vue.use(VueReCaptcha, {
    siteKey: recaptchaConfig.siteKey
})



// Create a Vue instance with `i18n` option

let app = '';
firebase.auth().onAuthStateChanged(() => {
    if (!app) {
        new Vue({
            data: {
                localeChanges: 0,
                currentUser: (firebase.auth().currentUser) ? firebase.auth().currentUser.email : null,
                title: null,
                categories: {
                    loading: false,
                    data: []
                },
                currencyRate: {
                    USDtoBRL: null,
                    EURtoBRL: null,
                    BRLtoUSD: null,
                    EURtoUSD: null
                }

            },
            router,
            store,
            i18n,
            render: h => h(App)
        }).$mount('#app')
    }
})