<template>
    <div>
        <div class="content-header">
            <div class="filters">
            </div>
            <button class="button" style="margin-right: 5px" @click="exportTable()">Exportar</button>
            <button class="button green" @click="add()">Adicionar</button>
        </div>
        <table>
            <thead>
                <tr><th>Nome</th><th>Email</th><th>Data de cadastro</th><th>Status</th><th></th></tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in itemsList" :key="index">
                    <td>{{ item.name }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.createdAt }}</td>
                    <td>{{ item.status }}</td>
                    <td>
                        <div class="buttons-s">
                            <!-- <button class="button button-small link" @click="edit(item.id)"><i class="icon-mode_edit"></i></button> -->
                            <button class="button button-small link red" @click="remove(item.id)"><i class="icon-delete_forever"></i></button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        
    </div>
</template>
<script>
// const json2xls = require('json2xls');
// import FileSaver from 'file-saver';


export default {
    name: "SubscribeList",
    components: {
    },
    data(){
        return {
            showForm: false,
            list: [],
            loading: false,
            documentId: null,
            headers: {
                docId: 'ID',
                name: 'Nome',
                email: 'Email',
                createdAt: 'Criado em',
                status: 'Status'
            }
        }
    },
    mounted(){
        this.loadList()
    },
    methods: {
      
        convertToCSV(objArray) {
            var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
            var str = '';

            for (var i = 0; i < array.length; i++) {
                var line = '';
                for (var index in array[i]) {
                    if (line != '') line += ','
                    line += array[i][index];
                }
                str += line + '\r\n';
            }
            return str;
        },
        exportTable(){
            var fileTitle = 'Lista de inscritos'; 
            this.exportCSVFile(this.headers, this.itemsList, fileTitle);
        },

        exportCSVFile(headers, items, fileTitle) {
            if (headers) {
                items.unshift(headers);
            }

            // Convert Object to JSON
            var jsonObject = JSON.stringify(items);

            var csv = this.convertToCSV(jsonObject);

            var exportedFilenmae = fileTitle + ' - ' + this.$moment().format('X') + '.csv' || 'export.csv';

            var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, exportedFilenmae);
            } else {
                var link = document.createElement("a");
                if (link.download !== undefined) { // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", exportedFilenmae);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        },
        add(){
            this.documentId = null
            this.showForm = true
        },
        edit(docId){
            this.documentId = docId
            this.showForm = true
        },
        remove(docId){
            this.$db.collection("subscribes").doc(docId).delete().then(()=>{
                this.$Message({message: 'Categoria removida com sucesso', duration: 3000})
            })
        },
        loadList(){
            this.loading = true
            this.$db.collection("subscribes").onSnapshot(snap => {
            let collection = []
            snap.forEach(doc => {
                let data = doc.data();
                data.id = doc.id
                collection.push(data)
            })
            this.list = collection
            this.loading = false
            }) 
        },
        closeForm(){
            this.showForm = false
            this.documentId = null
        }
    },
    computed: {
        itemsList(){
            let items = []
            this.list.forEach((item) => {
                let row = {
                    id: item.id,
                    name: item.name,
                    email: item.email,
                    createdAt: this.$moment(item.createdAt, 'X').format('DD/MM/YYYY HH:mm:ss'),
                    status: (item.status) ? this.$t('common.status.active') : this.$t('common.status.inactive')
                }
                items.push(row)
            })
            return items
        }
    }
}
</script>
<style lang="scss">
    .content-header{
        display: flex;
        .filters{
            flex: 1 1 90%;
        }
        .button{
            flex: 1 1 10%;
        }
    }
</style>