<template>
  <div class="card-page">
    <div slot="body">
        <p>{{ $t("pages.lostPassword.subtitle")}}</p>
      <div class="form-input">
        <label for="email">{{ $t("pages.lostPassword.email")}}</label>
        <input type="email" id="email" v-model="form.email">
      </div>
    </div>
    <button class="button full" @click="login()">{{ $t("pages.lostPassword.send") }}</button>
    <div class="buttons">
        <router-link class="button white button-link full" to="/login">{{ $t("pages.lostPassword.rememberPassword")}}</router-link>
    </div> 
  </div>
</template>
<script>
export default {
    name: "LostPassword",
    data(){
      return {
        form: {
          email: '',
        },
      }
    },
    methods: {
        login(){
            this.$firebase.auth.sendPasswordResetEmail(this.form.email).then(() => {
                console.log('Verifique seu e-mail um e-mail com mais informações')
            }).catch(function(error) {
                console.log(error)
            }); 
        }
    }
}
</script>