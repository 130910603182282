<template>
    <div class="">
        <label>Texto sobre (Portugues)</label>
        <vue-editor v-model="form.pt"></vue-editor>
        <hr>
        <label>Texto sobre (Ingles)</label>
        <vue-editor v-model="form.en"></vue-editor>
        <button class="button" @click="save()">Salvar</button>
    </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
export default {
    name: 'AboutForm',
    components: {
        VueEditor
    },
    data(){
        return{
            id: '',
            form: {
                pt: '',
                en: ''
            }
        }
    },
    mounted(){
        this.loadAbout()
    },
    methods: {
        loadAbout(){
            this.loading = true
            this.$db.collection("about").onSnapshot(snap => {
            snap.forEach(doc => {
                let data = doc.data();
                data.id = doc.id
                this.id = data.id
                this.form.pt = data.pt
                this.form.en = data.en
            })
            this.loading = false
            }) 
        },
        save(){
            this.$db.collection("about").doc(this.id).set(this.form).then(
                    result => {
                        alert('Sobre atualizado com sucesso')
                        if(result){
                            alert('Sobre atualizado com sucesso')
                        }
                    },
                    err => {
                       alert('Erro ao salvar: ' + err)

                    }
                );
        }
    }
}
</script>