<template>
    <div class="product">
        <!-- <template v-if="!loading"> -->
            <div class="product-header">
                <h3>{{ product.categoryName }}</h3>
                <span class="drop">
                    <a href="javascript:void(0)" @click="showCategories = !showCategories">{{ $t('common.browseAll')}} <i :class="{'icon-expand_more': !showCategories, 'icon-expand_less': showCategories}"></i></a>
                    <ul v-if="showCategories">
                        <router-link :to="'/'+$t('routes.products')">{{ $t('header.nav.products')}}</router-link>
                        <router-link v-for="(category, id) in $root.categories.data" :key="id" :to="'/products/'+category.uri[$i18n.locale]">{{ category.name[$i18n.locale] }}</router-link>
                    </ul>
                </span>
            </div>
            <div class="product-body">
                <div class="product-main">
                    <!-- <span class="new text-color red">{{ $t('common.new') }}</span> -->
                    <h1 class="text-color dark">{{ product.name }}</h1>
                    <h2 v-if="product.scientificName"><em>{{ product.scientificName }}</em></h2>
                    <h4>{{ product.pricing }}</h4>
                    
                    <div class="form-input">
                        <label>{{ $tc('common.location', productCountries.length)}}:</label>
                        <template v-if="showFlag">
                            <div class="tags" v-if="productCountries.length">
                                <div class="tag" v-for="(flag,i) in productCountries" :key="i"><img :src="getImgUrl(flag.alpha2)" height="22" :alt="flag.name" style="margin: 2px 5px -6px 0px">{{  flag.name }}</div>
                            </div>
                            <strong v-else><i>{{ $t('common.undefined') }}</i></strong>
                        </template>
                        <template v-if="!showFlag">
                            <strong v-if="productCountries">{{ productCountries }}</strong>
                            <strong v-else><i>{{ $t('common.undefined') }}</i></strong>
                        </template>
                    </div>

                    <div class="form-input">
                        <!-- <label>{{ $t('common.presentation')}}: <strong>{{typeLabel}}</strong></label> -->
                        <!-- <div class="icons">
                            <span class="input-icon" v-for="(type, i) in product.types" :key="i"><input :id="type.sku" type="radio" v-model="selected.sku" :value="type.sku"><label :for="type.sku"><span>{{ $t('common.productType.'+type.name) }}</span></label></span>
                        </div> -->
                        <div class="tags">
                            <label>{{ $tc('common.presentation', product.types.length)}}: </label>
                            <div class="tag" v-for="(type, i) in product.types" :key="i" @click="selected.sku = type.sku" :class="{'selected': selected.sku  == type.sku}">
                                {{ $t('common.productType.'+type.name) }}
                            </div>
                        </div>
                    </div>
                    <div class="buttons" v-if="false">
                        <button class="button large">{{ $t('common.addToOrder') }}</button>
                        <button class="button button-text text-color" v-tooltip="{content: favorite, delay: { show: 500, hide: 300,}}" @click="updateFavorite()"><i class="icon-favorite_border"></i></button>
                    </div>
                    
                </div>
                <div class="product-gallery">
                    <figure><img :src="activeImage"></figure>
                    <nav>
                        <a href="javascript:void(0)" v-for="(type, i) in product.types" :key="i" @click="selected.sku = type.sku" :class="{'active': type.sku == selected.sku}">
                            <img :src="type.url">
                        </a>
                    </nav>
                </div>
                <div class="form-input" v-if="product.description">
                    <label>{{ $t('common.description') }}</label>
                    <div class="product-description" v-html="product.description"></div>
                </div>
            </div>
        <!-- </template>
        <template v-if="loading">
            <div class="flex">
                <h3 class="text-center">{{ $t('common.loading') }} <br><br> <img src="../assets/img/preloader.svg" alt="preloader"></h3>
                
            </div>
            
        </template> -->
    </div>
</template>
<script>
import country_en from '../assets/world-countries/data/en/world.json'
import country_pt from '../assets/world-countries/data/pt/world.json'
export default {
    name: "ProductView",
    data(){
        return {
            showFlag: true,
            country: {
                pt: country_pt,
                en: country_en
            },
            title: '',
            categories: {},
            selected: {
                sku: null,
                quantity: 1,
            },
            showCategories: false,
            loading: false,
            ready: false
        }
    },
    props: {
        list: {
            type: Array,
            required: true
        }
    },
    mounted() {
        // this.loading = true
        // setTimeout(() => {
            this.loading = false
        // },500)
        // this.loadProduct()
    },
    methods: {
         
        updateFavorite(){
            this.$Message({message: 'This is message tip !', type: 'success', duration: 1000})
        },
        getImgUrl(pic) {
            return require('../assets/flags/'+pic+'.png')
        }
        
    },
    computed: {
        product: {
            get(){
                let product = {}
                if(!this.loading){
                    
                    // this.loading = true
                    let list = JSON.parse(JSON.stringify(this.list))
                    list.forEach((item) => {
                        if(item.uri[this.$i18n.locale] == this.$route.params.product){
                            

                            this.$root.title = item.name[this.$i18n.locale]
                            this.title = item.name[this.$i18n.locale]
                            item.name = item.name[this.$i18n.locale]
                            item.uri = item.uri[this.$i18n.locale]
                            item.description = item.description[this.$i18n.locale]
                            item.pricing = null
                            item.categoryName = this.$root.categories.data[item.category].name[this.$i18n.locale]
                            item.categoryUri = this.$root.categories.data[item.category].uri[this.$i18n.locale]
                            product = item
                            // this.loading = false
                            this.selected.sku = item.types[0].sku

                            // this.$router.push({path: '/products/'+item.categoryUri+'/'+item.uri , params: { category: item.categoryUri, product: item.uri  }})
                        }
                    })
                }
                return product
            },
            set(nV){
                let originalPrice = 0
                nV.types.forEach((type) => {
                    if(type.sku == this.selected.sku){
                        originalPrice = type.price
                    }
                })
                
                if(!Object.keys(originalPrice).length) this.pricing = this.$t('common.priceOnRequest')
                
                if(this.$i18n.locale == 'en' && originalPrice.base == 'USD'){
                    this.pricing = this.$n(originalPrice.value, 'currency') + ' /' + originalPrice.metric
                    return true
                }
                if(this.$i18n.locale == 'en' && originalPrice.base == 'BRL'){
                    this.pricing = this.$n(originalPrice.value / this.$root.currencyRate.BRLtoUSD, 'currency') + ' /' + originalPrice.metric + ' ('+ this.$n(originalPrice.value, 'currency', 'en-US') +')'
                    return true
                }
                if(this.$i18n.locale == 'en' && originalPrice.base == 'EUR'){
                    this.pricing = this.$n(originalPrice.value / this.$root.currencyRate.EURtoUSD, 'currency') + ' /' + originalPrice.metric + ' ('+ this.$n(originalPrice.value, 'currency', 'eur') +')'
                    return true
                }

                if(this.$i18n.locale == 'pt' && originalPrice.base == 'BRL'){
                    this.pricing = this.$n(originalPrice.value, 'currency') + ' ' + originalPrice.metric
                    return true
                }
                if(this.$i18n.locale == 'pt' && originalPrice.base == 'USD'){
                    this.pricing = this.$n(originalPrice.value / this.$root.currencyRate.USDtoBRL, 'currency', 'pt-BR') + ' /' + originalPrice.metric + ' ('+ this.$n(originalPrice.value, 'currency', 'en-US') +')'
                    return true
                }
                if(this.$i18n.locale == 'pt' && originalPrice.base == 'EUR'){
                    this.pricing = this.$n(originalPrice.value / this.$root.currencyRate.EURtoBRL, 'currency', 'pt-BR') + ' /' + originalPrice.metric + ' ('+ this.$n(originalPrice.value, 'currency', 'eur')  +')'
                    return true
                }
                this.pricing = ''
                return true
            }
        },
        // categoryUri(){
        //     return this.$root.categories.data[this.product.category].uri[this.$i18n.locale]
        // },
        // categoryName(){
        //     return this.$root.categories.data[this.product.category].name[this.$i18n.locale]
        // },
        productCountries(){
            let list = [];
            let countryList = null;
            if(this.product.countryOrigin)
            this.product.countryOrigin.forEach((country) => {
                if(country != null){
                    countryList = this.country[this.$i18n.locale].filter((item) => {
                        if(item){
                            return item.alpha2.trim() == country.trim().toLowerCase()
                        }
                    })
                    countryList.forEach((item) =>{
                        if(item && !this.showFlag)
                            list.push(item.name)
                        if(item && this.showFlag)
                            list.push(item)
                    })
                }
            })
            if(this.showFlag){
                return list;
            }
            return list.join(', ')
            
        },
        typeLabel(){
            let label = ''
            if(Object.keys(this.product).length > 0){
                this.product.types.forEach((item) => {
                    if(item.sku == this.selected.sku){
                        label = this.$t('common.productType.'+item.name)
                    }
                })
            }
            return label 
        },
        activeImage(){
            let url = ''
            if(Object.keys(this.product).length > 0){
                this.product.types.forEach((item) => {
                    if(item.sku == this.selected.sku){
                        url = item.url
                    }
                })
            }
            return url
        }
        
    }
}
</script>
<style scoped>
h1{
    margin-bottom: .5em;
}
</style>