import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase';

import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Products from '../views/Products.vue'
import ContactUs from '../views/ContactUs.vue'
import Login from '../views/Login.vue'
import LostPassword from '../views/LostPassword.vue'
import Signup from '../views/Signup.vue'
import AccountHome from '../views/Account/Home.vue'
import AdminProductList from '../views/Account/Admin/ProductList.vue'
import AdminCategoryList from '../views/Account/Admin/CategoryList.vue'
import AdminSubscribeList from '../views/Account/Admin/SubscribeList.vue'
import MessagesList from '../views/Account/Admin/MessagesList.vue'
import AdminUsersList from '../views/Account/Admin/UsersList.vue'
import AdminAboutForm from '../views/Account/Admin/AboutForm.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        alias: '/sobre'
    },
    {
        path: '/contact',
        name: 'ContactUs',
        component: ContactUs,
        alias: '/contato'
    },
    {
        path: '/products',
        name: 'Products',
        component: Products,
        alias: '/produtos'
    },
    {
        path: '/products/:category',
        name: 'Products',
        component: Products,
        alias: '/produtos/:category'
    },
    {
        path: '/products/:category/:product',
        name: 'Products',
        component: Products,
        alias: '/produtos/:category/:product'
    },
    {
        path: '/account',
        name: 'AccountHome',
        component: AccountHome,
        meta: {
            requiresAuth: true
        },
        children: [{
                path: 'admin/products',
                name: 'AdminProductList',
                component: AdminProductList
            },
            {
                path: 'admin/categories',
                name: 'AdminCategoryList',
                component: AdminCategoryList
            },
            {
                path: 'admin/inscritos',
                name: 'AdminSubscribeList',
                component: AdminSubscribeList
            },
            {
                path: 'admin/users',
                name: 'AdminUsersList',
                component: AdminUsersList
            },
            {
                path: 'admin/messages',
                name: 'MessagesList',
                component: MessagesList
            },
            {
                path: 'admin/about',
                name: 'AboutForm',
                component: AdminAboutForm
            }
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        alias: '/entrar'
    },
    {
        path: '/lost-password',
        name: 'LostPassword',
        component: LostPassword,
        alias: '/esqueci-minha-senha'
    },
    {
        path: '/signup',
        name: 'Signup',
        component: Signup,
        alias: '/cadastrar'
    },
    {
        path: '*',
        redirect: '/'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const currentUser = firebase.auth().currentUser;
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    if (requiresAuth && !currentUser) next('/login');
    // else if (!requiresAuth && currentUser) next('home')
    else next();
})

export default router