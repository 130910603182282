<template>
    <div>
        <modal v-if="show" @close="show = false" :classes="'lg'">
            <h3 slot="header">Produto</h3>
            <div slot="body">
                <div class="row">
                    <div class="col-6">
                        <div class="form-input">
                            <label for="namept">Nome (português)</label>
                            <input v-model="form.name.pt" type="text" id="namept">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-input">
                            <label for="nameen">Nome (inglês)</label>
                            <input v-model="form.name.en" type="text" id="nameen">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-input">
                            <label for="descpt">Descrição (português)</label>
                            <textarea v-model="form.description.pt" id="descpt"></textarea>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-input">
                            <label for="descen">Descrição (inglês)</label>
                            <textarea v-model="form.description.en" id="descen"></textarea>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-input">
                            <label for="name">Nome científico</label>
                            <input v-model="form.scientificName" type="text" id="scienfic">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-input">
                            <label for="countryOrigin">País de Origem (separado por virgula)</label>
                            <input v-model="countryOrigin" type="text" id="countryOrigin">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-input">
                            <label for="name">Categoria</label>
                            <select v-model="form.category">
                                <option v-for="(category, id) in categories.data" :value="id" :key="id">{{ category.name[$i18n.locale] }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <fieldset>
                    <legend>Tipos</legend>
                    <template v-if="!loadingTypes">
                    <div class="item-list" style="display: flex" v-for="(type, index) in form.types" :key="index">
                        <span class="input-checkbox">
                            <input type="checkbox" :id="'item-'+index" v-model="selected.types" :value="index">
                            <span></span>
                            <label :for="'item-'+index" style="width: unset"></label>
                        </span>
                        <types :initial="type" :callback="updateType"  :index="index"></types>
                    </div>
                    <div class="buttons">
                        <button class="button" @click="addType()">Adicionar Tipo</button>
                        <button v-if="selected.types.length" class="button red" @click="removeTypes()">Remover selecionados</button>
                    </div>
                    </template>
                    <template v-if="loadingTypes">
                        <h4>Aguarde...</h4>
                    </template>
                </fieldset>
                <div class="row">
                    <div class="col-12">
                        <div class="form-input">
                            <label for="name">Status</label>
                            <div class="input-radio">
                                <input type="checkbox" id="status-form" value="1" v-model="form.status">
                                <span></span>
                                <label for="status-form">{{ status }}</label>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
            <template slot="footer">
                <button class="button red" @click="show = false">Cancelar</button>
            <button class="button green" @click="save()">Salvar</button>
            </template>
          </modal>
    </div>
</template>
<script>
import Modal from '@/components/Modal.vue'
import Types from '@/components/Types.vue'
import jsFunction from '@/helpers/jsFunctions.js'
export default {
    name: "ProductForm",
    components: {
        Modal,
        Types
    },
    data(){
        return {
            countryOrigin: '',
            show: true,
            form: {
                countryOrigin: [],
                name: {
                    pt: '',
                    en: ''
                },
                category: '',
                types: [],
                uri: {
                    pt: this.ptSlugfy,
                    en: this.enSlugfy
                },
                description: {
                    pt: '',
                    en: ''
                },
                scientificName: ''
            },
            assets: {
                
            },
            selected: {
                types: []
            },
            loadingTypes: false
        }
    },
    props: {
        id: {
            required: false,
            type: String
        },
        categories: {
            required: false,
            type: Object
        }
    },
    watch: {
        'show'(nV){
            if(!nV){
                this.$emit('close')
            }
        },
    },
    mounted(){
        if(this.id != null){
            this.$db.collection("products").doc(this.id).get().then(snap => {
                    let data = snap.data();
                    this.form = data

                    this.countryOrigin = data.countryOrigin.join(',');
            })
        }
    },
    computed: {
        status(){
            return (this.form.status) ? 'Ativo' : 'Inativo'
        }
    },
    methods: {
        updateType(index, form){
            this.form.types[index] = form
        },
        addType(){
            this.form.types.push({});
        },
        save(){
            this.form.countryOrigin = this.countryOrigin.split(',');
            this.form.uri.pt = jsFunction.slugsTidy(this.form.name.pt)
            this.form.uri.en = jsFunction.slugsTidy(this.form.name.en)
            this.form.date = this.$moment().format('x')

            if(this.id){
                this.$db.collection("products").doc(this.id).set(this.form).then(
                    result => {
                        alert('Produto atualizado com sucesso')
                        if(result){
                            alert('Produto atualizado com sucesso')
                        }
                    },
                    err => {
                       alert('Erro ao salvar: ' + err)

                    }
                );
            }
            else{
                this.$db.collection("products").add(this.form).then(
                    result => {
                        if(result){
                            alert('Produto adicionado com sucesso')
                        }
                    },
                    err => {
                       alert('Erro ao salvar: ' + err)
                    }
                );
            }
            

            // this.form.createdAt = this.$firebase.firestore.Timestamp.fromDate(new Date());

        },
        removeTypes(){
            this.loadingTypes = true
            this.selected.types.forEach((type) => {
                this.$delete(this.form.types, type)
            })
            this.selected.types = []
            setTimeout(() => {
                this.loadingTypes = false
            },10)
        } 
    }
    
}
</script>