<template>
  <div class="card-page">
    <div slot="body">
      <!-- <h4>Com seu perfil nas redes sociais</h4>
      <div class="buttons full margin">
        <button class="button full google"><span></span>Google</button>
        <button class="button full facebook">Facebook</button>
        <button class="button full twitter">Twitter</button>
      </div>
      <span class="divider">ou</span> -->
      <div class="form-input">
        <label for="email">{{ $t('pages.login.email')}}</label>
        <input type="email" id="email" v-model="form.email">
      </div>
      <div class="form-input">
        <label for="password">{{ $t('pages.login.password')}}</label>
        <input type="password" id="password" v-model="form.password">
        <router-link to="/lost-password">{{ $t('pages.login.lostPassword')}}</router-link>
      </div>
    </div>
    <button class="button full" @click="login()">{{ $t('pages.login.login')}}</button>
    <router-link class="button white full" to="/signup">{{ $t('pages.login.notRegistered')}}</router-link>

<!-- entre 3 e 5, dupla de algodão
100 mascaras. -->
            
  </div>
</template>
<script>
export default {
    name: "LoginView",
    data(){
      return {
        form: {
          email: '',
          password: ''
        },
      }
    },
    methods: {
         login(){
      this.$firebase.auth().signInWithEmailAndPassword(this.form.email, this.form.password).then(
        () => {
          this.$root.currentUser = this.$firebase.auth().currentUser.email
          this.$router.push({path: '/account/'})
        },
        (err) => {
           alert('Algo deu errado. ' + err.message)
        })
    },
    }
}
</script>