<template>
    <div class="container single-content contact-page">
        <div class="contact">
            <h3><i class="icon-mail_outline"></i> <span>{{ $t('pages.contact.phone') }}</span></h3>
            <h4>+55 (48) 3304-1892</h4>
            <h3><i class="icon-room"></i> <span>{{ $t('pages.contact.address') }}</span></h3>
            <h4>{{ $t('pages.contact.street', { street: 'Patrício Farias', number: '101', class: '716 - Ed. Infinty office'})}}</h4>
            <h4>Itacorubi, Florianópolis - SC - {{$t('pages.contact.brazil')}}</h4>
            <h4>{{ $t('pages.contact.postalcode') }} 88034-132</h4>
        </div>
        <div class="form">
            <template v-if="!sent">
                <h3>{{$t('pages.contact.form.title')}}</h3>
                <label>{{$t('pages.contact.form.name')}}</label>
                <input type="text" v-model="form.name">
                <span v-if="hasSend && hasError.name && errors.name" class="error-input">{{ errors.name}}</span>

                <label>{{$t('pages.contact.form.email')}}</label>
                <input type="text" v-model="form.email">
                <span v-if="hasSend && hasError.email && errors.email" class="error-input">{{ errors.email}}</span>

                <label>{{$t('pages.contact.form.phone')}}</label>
                <input type="text" v-model="form.phone">
                <span v-if="hasSend && hasError.phone && errors.phone" class="error-input">{{ errors.phone}}</span>

                <label>{{$t('pages.contact.form.subject')}}</label>
                <select type="text" v-model="form.subject">
                    <option v-for="subject in subjects" :key="subject">{{ subject }}</option>
                </select>
                <span v-if="hasSend && hasError.subject && errors.subject" class="error-input">{{ errors.subject}}</span>

                <label>{{$t('pages.contact.form.message')}}</label>
                <textarea v-model="form.message"></textarea>
                <span v-if="hasSend && hasError.message && errors.message" class="error-input">{{ errors.message}}</span>
                <button type="button" class="button" @click="sendEmail()">{{$t('pages.contact.form.send')}}</button>  
            </template> 
            <template v-else>
                <img src="../assets/img/paperplane.png" width="100%" alt="">
                <h2>{{$t('pages.contact.form.thanks')}}</h2>
                <h3>{{$t('pages.contact.form.success')}}</h3>
                <button type="button" class="button" @click="sent = false">{{$t('pages.contact.form.back')}}</button>  
            </template>
        </div>
    </div>
</template>
<script>
export default {
    name: "ContactUs",
    data(){
        return {
            sent: false,
            hasSend: false,
            errors: {
                name: null,
                email: null,
                phone: null,
                subject: null,
                message: null
            },
            form: {
                name: '',
                email: '',
                phone: '',
                subject: '',
                message: ''
            }
        }
    },
    computed:{
        subjects(){
            let subjects = []
            let keys = ['questions', 'order', 'financial', 'others'];
            keys.forEach((key) => {
                subjects.push(this.$t(`pages.contact.subjects.${key}`))
            })
            return subjects
        },
        hasError(){
            let keys = Object.keys(this.form)
            let hasError = {}
            keys.forEach(key => {
                if(this.form[key].length < 3){
                    if(this.hasSend){
                        this.errors[key] = this.$t(`pages.contact.form.${key}`) + ' ' + this.$t('pages.contact.form.required')
                    }
                    hasError[key] = true
                }
                else{
                    hasError[key] = false
                    this.errors[key] = null
                }
            })
            return hasError
        }
    },
    methods: {
        hasAnyError(){
            for(let key in this.hasError){
                if(this.hasError[key]){
                    return true
                }
            }
            return false
        },
        sendEmail(){
            this.hasSend = true
            if(!this.hasAnyError()){
                this.$recaptcha("contactus").then((token) => {
                    console.log(token)
                    this.form.date = this.$moment().format('x')
                    console.log('enviando....')
                    this.$db.collection("messages").add(this.form).then(
                        () => {

                            this.sent = true
                        },
                        err => {
                            this.$Message({message: this.$t('pages.contact.form.error') + ':' + err, duration: 3000})
                        }
                    );
                });
             }
        }
    }
}
</script>
<style scoped>
    select{
        padding: 8px;
        width: 100%;
        border: #e9e9e9 2px solid;
        border-radius: 3px;
    }
    textarea{
        width: 100%;
        height: 100px;
        line-height: 15px;
        font-family: Lato, sans-serif;
        padding: 8px 5px;
    }

</style>