<template>
    <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container" :class="[classes]">
                <a class="close" href="javascript:void(0)" @click="$emit('close')"><span>&times;</span></a>
              <div class="modal-header">
                <slot name="header">
                </slot>
              </div>

              <div class="modal-body">
                <slot name="body">
                </slot>
              </div>

              <div class="modal-footer">
                <slot name="footer">
                  <button class="button modal-default-button" @click="$emit('close')">
                    OK
                  </button>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </transition>
</template>
<script>
export default {
    name: 'ModalComponent',
    props: {
        classes: {
            type: String,
        }
    }
}
</script>