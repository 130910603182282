<template>
    <div>
        <div class="content-header">
            <div class="filters">
            </div>
            <button class="button green" @click="add()">Adicionar</button>
        </div>
        <table>
            <thead>
                <tr><th>Nome</th><th>Status</th><th></th></tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in itemsList" :key="index">
                    <td>{{ item.name }}</td>
                    <td>{{ item.status }}</td>
                    <td>
                        <div class="buttons-s">
                            <button class="button button-small link" @click="edit(item.id)" style="margin-right: 5px"><i class="icon-mode_edit"></i></button>
                            <button class="button button-small link red" @click="remove(item.id)"><i class="icon-delete_forever"></i></button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <category-form v-if="showForm" :id="documentId" @close="closeForm">

        </category-form>
    </div>
</template>
<script>
import CategoryForm from './CategoryForm.vue'
export default {
    name: "CategoryList",
    components: {
        CategoryForm
    },
    data(){
        return {
            showForm: false,
            list: [],
            loading: false,
            documentId: null
        }
    },
    mounted(){
        this.loadCategories()
    },
    methods: {
        add(){
            this.documentId = null
            this.showForm = true
        },
        edit(docId){
            this.documentId = docId
            this.showForm = true
        },
        remove(docId){
            this.$db.collection("categories").doc(docId).delete().then(()=>{
                this.$Message({message: 'Categoria removida com sucesso', duration: 3000})
            })
        },
        loadCategories(){
            this.loading = true
            this.$db.collection("categories").onSnapshot(snap => {
            let collection = []
            snap.forEach(doc => {
                let data = doc.data();
                data.id = doc.id
                collection.push(data)
            })
            this.list = collection
            this.loading = false
            }) 
        },
        closeForm(){
            this.showForm = false
            this.documentId = null
        }
    },
    computed: {
        itemsList(){
            let items = []
            this.list.forEach((item) => {
                let row = {
                    id: item.id,
                    name: item.name[this.$i18n.locale],
                    status: (item.status) ? this.$t('common.status.active') : this.$t('common.status.inactive'),
                }
                items.push(row)
            })
            return items
        }
    }
}
</script>
<style lang="scss">
    .content-header{
        display: flex;
        .filters{
            flex: 1 1 90%;
        }
        .button{
            flex: 1 1 10%;
        }
    }
</style>