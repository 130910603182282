<template>
    <address>
        <div class="container">
            <p>© CampêcheBR {{ $moment().format('Y') }}</p>
            <p>{{ $t('footer.address.document') }}:  01.260.856/0001-69</p>
        </div>
    </address>
</template>
<script>
export default {
    name: "FooterAddress"
}
</script>